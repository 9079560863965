@import (inline) "npm://normalize.css/normalize.css";
@import (inline) "../bootstrap/bootstrap.css";
@import (inline) "npm://leaflet/dist/leaflet.css";

@font-face {
    font-family: 'headline';
    src: url('../fonts/oxfamglobalheadline-webfont.eot');
    src: url('../fonts/oxfamglobalheadline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oxfamglobalheadline-webfont.woff2') format('woff2'),
         url('../fonts/oxfamglobalheadline-webfont.woff') format('woff'),
         url('../fonts/oxfamglobalheadline-webfont.ttf') format('truetype'),
         url('../fonts/oxfamglobalheadline-webfont.svg#oxfam_global_headlineregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?gfyhgw');
    src:    url('../fonts/icomoon.eot?gfyhgw#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?gfyhgw') format('truetype'),
        url('../fonts/icomoon.woff?gfyhgw') format('woff'),
        url('../fonts/icomoon.svg?gfyhgw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

////////////////////////////////////////////////////////////////////////////////
// variables

@em: 18em;

@c-green: #61A534;
@c-green-dark: #0c884a;
@c-green-bg: #EAEADE;
@c-red: #e70052;
@c-dark: #121212;
@c-yellow: #d1c32a;
@c-orange: #F16E22;
@c-charcoal: #333333;
@c-text: #fff;

////////////////////////////////////////////////////////////////////////////////
// helper functions

.reset {
    margin: 0;
    padding: 0;
}

.horizontal-center {
    left: 50%;
    transform: translateX(-50%);
}

.absolute-center {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
}

.hidden {
    display: none !important;
    pointer-events: none;
}

.slide-away {
    transform: translateX(100%);
    .close-popup {
        .direction-arrow {
            transform: rotate(180deg) translateX(4px);
        }
    }
}

.clear-fix { clear: both; }

.btn-color(@c) {
    background: @c;
    &:hover { background: lighten(@c, 5%)}
}

.headline-font {
    font-family: 'headline', 'Arial', sans-serif;
}


////////////////////////////////////////////////////////////////////////////////
// typography

body {
    font-size: 15px;
    overflow-x: hidden;
    @media all and (max-width: 720px) {
        font-size: 12px;
    }
}


////////////////////////////////////////////////////////////////////////////////
// common elements

#map {
    height: 75vh;
    max-height: 500px;
}

.logo {
    display: inline-block;
    width: 270px;
    height: auto;
    float: left;
    @media all and (max-width: 1260px) {
        width: 200px;
    }
}

////////////////////////////////////////////////////////////////////////////////
// blocks

#oxfam-map-container {
    position: relative;
    .custom-popup {
        position: absolute;
        background: @c-text;
        width: calc(100% ~"-" 55/@em);
        max-width: 520px;
        top: 20/@em; right: 0; bottom: 20/@em;
        padding: 10/@em 30/@em 25/@em 30/@em;
        box-shadow: -10px 10px 37px -12px rgba(0,0,0,0.75);
        will-change: transform;
        transition: 0.3s transform ease-in-out;
        z-index: 400 !important;
        .read-more {
            display: block;
            margin-top: 0.75em;
            color: @c-green;
            text-decoration: none;
            font-weight: bold;
            &:hover {
                color: lighten(@c-green, 5%);
            }
        }
        .desc {
            .read-more, + p a {
                color: inherit;
                font-weight: normal;
                &:hover { color: inherit; }
            }
        }
        .custom-popup-wrapper {
            height: 100%;
            overflow: auto;
            box-sizing: border-box;
        }
        .custom-popup-inner {
            .title {
                .headline-font;
                margin-top: 0;
                a { color: inherit; }
            }
            .img-holder {
                width: 100%;
                float: left;
                margin-bottom: 20/@em;
                a { margin-top: 0; }
                img { width: 100%; }
            }
            .custom-popup-content {
                width: 100%;
                box-sizing: border-box;
                float: right;
                p { margin: 0; }
            }
        }
        .close-popup {
            display: inline-block;
            padding: 7.5/@em 15/@em;
            position: absolute;
            left: -40/@em;
            top: 0;
            background: @c-text;
            box-shadow: -10px 10px 37px -12px rgba(0,0,0,0.75);
            .direction-arrow { display: inline-block; }
            &:hover {
                cursor: pointer;
                color: lighten(@c-charcoal, 10%);
            }
        }
    }
    .map-pin {
        margin-top: -20px !important;
        margin-left: -10px !important;
        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50% 50% 50% 0;
            position: relative;
            transform: rotate(-45deg);
            background: @c-charcoal;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            color: #fff;
            border-radius: 100%;
            box-sizing: border-box;
            border: 2px solid @c-charcoal;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .leaflet-control-container {
        position: relative;
        z-index: 400;
        .leaflet-bottom, .leaflet-top { z-index: 2; }
    }
    .leaflet-tooltip {
        background: #fff;
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding:0.125em;
        font-weight: bold;
        &:after { display: none; }
    }
    .leaflet-container { background: white; }
    .leaflet-control-attribution { display: none; }
}

#regions {
    overflow: hidden;
    .region-select {
        display: inline-block;
        padding: 10/@em 10/@em;
        color: @c-charcoal;
        font-weight: bold;
        float: left;
        &:hover, &.active {
            background: @c-green;
            color: #fff;
            text-decoration: none;
        }
    }
}

#results_table {
    position: relative;
    z-index: 500 !important;
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid lighten(@c-charcoal, 50%);
    .result {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 15/@em 7.5/@em;
        background: lighten(@c-green-bg, 7.5%);
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid lighten(@c-charcoal, 50%);
        @media all and (min-width: 561px) and (max-width: 920px) {
            width: 50%;
            &:nth-of-type(2n-1) { border-right: 1px solid lighten(@c-charcoal, 50%) }

        }
        @media all and (min-width: 921px) {
            width: 100/3%;
            &:nth-of-type(3n-1), &:nth-of-type(3n+1) { border-right: 1px solid lighten(@c-charcoal, 50%) }

        }
        &:hover {
            cursor: pointer;
            background: @c-green-bg;
        }
        h3 {
            margin: 0;
            &:first-of-type {
                width: 4rem;
            }
        }
        div[class*=flag-] {
            &:after {
                content: '';
                display: inline-block;
                vertical-align: top;
                width: 4rem; height: 4rem;
                margin-right: 3rem
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// FLAGS

.flag-BMU:after { background: url(../img/flags/bmu.svg) center center/100% no-repeat }
.flag-CYM:after { background: url(../img/flags/cym.svg) center center/100% no-repeat }
.flag-NLD:after { background: url(../img/flags/nld.svg) center center/100% no-repeat }
.flag-CHE:after { background: url(../img/flags/che.svg) center center/100% no-repeat }
.flag-SGP:after { background: url(../img/flags/sgp.svg) center center/100% no-repeat }
.flag-IRL:after { background: url(../img/flags/irl.svg) center center/100% no-repeat }
.flag-LUX:after { background: url(../img/flags/lux.svg) center center/100% no-repeat }
.flag-CUW:after { background: url(../img/flags/cuw.svg) center center/100% no-repeat }
.flag-HKG:after { background: url(../img/flags/hkg.svg) center center/100% no-repeat }
.flag-CYP:after { background: url(../img/flags/cyp.svg) center center/100% no-repeat }
.flag-BHS:after { background: url(../img/flags/bhs.svg) center center/100% no-repeat }
.flag-JEY:after { background: url(../img/flags/jey.svg) center center/100% no-repeat }
.flag-BRB:after { background: url(../img/flags/brb.svg) center center/100% no-repeat }
.flag-MUS:after { background: url(../img/flags/mus.svg) center center/100% no-repeat }
.flag-VGB:after { background: url(../img/flags/vgb.svg) center center/100% no-repeat }


@keyframes fade-in {
    from {  opacity: 0;       }
    to {    opacity: 1;    }
}

.leaflet-label {
    font-size: 1.25em;
    padding: 0.5em;
    background: #fff;
    white-space: nowrap;
}
